import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
//import { Auth, Amplify } from 'aws-amplify';
//import Auth from '@aws-amplify/auth';
//import Amplify from '@aws-amplify/core';
//import "../node_modules/bootstrap/dist/js/bootstrap.min.js";



//const App = React.lazy(() => import('./App.js'));
//const {Auth, Amplify} = React.lazy(() => import('aws-amplify'));


ReactDOM.render(
  <React.StrictMode>
    <Router>
	    <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
