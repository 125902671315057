import React, { useEffect} from "react";
//import API from '@aws-amplify/api';
//import Spinner from './Spinner.js';
import '../App.css';
import {Polyline} from '../components/Polyline.tsx';
import {Circle} from '../components/circle.tsx';
//import {Polygon} from '../components/polygon.tsx';
import { encode } from "@googlemaps/polyline-codec";





//import initMap from './script.js';
//import React from "react";
import {APIProvider, 
  Map,  
  AdvancedMarker,
  Pin} from '@vis.gl/react-google-maps';

type Poi ={ key: string, location: google.maps.LatLngLiteral }
const locations: Poi[] = [
  {key:'Ranka Court',location: { lat: 12.9695730026911, lng: 77.6270253627039}},
  {key:'Lake View Apartments',location: { lat: 12.9794424558954, lng: 77.6185281248875}},
  {key:'Nandidurg Road',location: { lat: 13.002358149054128, lng: 77.6003320196645}},
  {key:'NAFL',location: { lat: 12.9876388225918, lng: 77.54205298454}}
  /*
  {key: 'start', location: { lat: 13.1085864, lng: 77.5920441  }},
  {key: 'GKVK layout', location: { lat: 13.081481250744172, lng: 77.60288226555177  }},
  {key: 'L&T Apartments', location: { lat: 13.069281090616073, lng: 77.59030093315347 }},
  {key: 'Kodigehalli Signal', location: { lat: 13.056353589384702, lng: 77.59360412490226  }},
  {key: 'Hebbal Flyover', location: { lat: 13.039407439321558, lng: 77.58919643862752  }},
  {key: 'CBI', location: {lat: 13.026621469313568, lng: 77.58604034034929  }},
  {key: 'Sadashiv Nagar', location: { lat: 13.007535300448469, lng: 77.58025081115649  }},
  {key: '17th Cross, Malleshwaram', location: { lat: 13.007696551672518, lng: 77.56361443647248  }},
  {key: 'MES College', location: { lat: 13.005443082955114, lng: 77.56379044395976  }},
  {key: 'Sobha Indraprastha', location: { lat: 12.979269165565674, lng: 77.56427179401084 }},
  {key: 'NAFL', location: {lat: 12.987739817422474, lng: 77.54225943787723  }},
*/

];


const path = [
  [12.9727756015671, 77.6269229627047],
  [12.971019155017059, 77.6236613966742],
  [12.97327744116034, 77.6223739363989],
  [12.972942881544034, 77.6203998306436],
  [12.975619345872332, 77.6203139999586],
  [12.979299437284219, 77.6179965714632],
  [12.979383075092295, 77.6141341906376],
  [12.980888550825437, 77.610443471182],
  [12.990172116314755, 77.6138766985826],
  [12.993517460281687, 77.6068385824114],
  [12.995524645028668, 77.6017745719956],
  [12.99502285036318, 77.6018604026806],
  [13.0017969927595, 77.5994571435002],
  [13.0053094382408, 77.5936206569192],
  [13.0102435038419, 77.5906165829438],
  [13.0137558297079, 77.5874408475982],
  [13.0145920904372, 77.5659831763447],
  [13.0150938455206, 77.5640949012744],
  [13.0184388534524, 77.5579150919534],
  [13.0148429681058, 77.555254340718],
  [13.0041386286055, 77.5537952190727],
  [13.0038877401077, 77.549417854137],
  [12.9922629615752, 77.5513061292073],
  [12.9875794437471, 77.5498470075621],
  [12.9895030421138, 77.5457271346814],
  [12.9883321579255, 77.5454696426264],
  [12.9887503314835, 77.5422939072808],
  [12.9877467137616, 77.5416072618007]
  /*
  [13.1085864,  77.5920441],
  [13.081481250744172, 77.60288226555177],
  [13.069281090616073, 77.59030093315347],
  [13.056353589384702, 77.59360412490226 ],
  [13.039407439321558, 77.58919643862752],
  [13.026621469313568, 77.58604034034929],
  [13.007535300448469, 77.58025081115649],
  [13.007696551672518, 77.56361443647248],
  [13.005443082955114, 77.56379044395976],
  [12.979269165565674, 77.56427179401084],
  [12.987739817422474, 77.54225943787723]
  */
];

//console.log(encode(path, 5));


const PoiMarkers = (props: {pois: Poi[]}) => {
  return (
    <>
      {props.pois.map( (poi: Poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}>
        <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
        </AdvancedMarker>
      ))}
    </>
  );
};

const INITIAL_CENTER = {lat: 12.9876388225918, lng: 77.54205298454};



export default function SimpleMap(){

  const [center, setCenter] = React.useState(INITIAL_CENTER);

  const MINUTE_MS = 60000;

  useEffect(() => {
    const interval = setInterval(async () => {
      let fetchRes = await fetch("https://dg39f8qyph.execute-api.ap-south-1.amazonaws.com/prod/getloc?routeId=98");
      let res = await fetchRes.json();
      console.log(res);
      console.log('Logs every 1 minute');
      let curLoc={lat: Number(res.lat), lng: Number(res.longitude)}
      setCenter(curLoc);
    }, MINUTE_MS);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }}>
 <APIProvider apiKey="AIzaSyCmTthk4oVbQX2-A_Pt7K7EMSOFylY_DRs" onLoad={() => console.log('Maps API has loaded.')}>
   <Map
      defaultZoom={13}
      mapId="vektura"
      //onGoogleApiLoaded={({map, maps}) => renderPolylines(map, maps)}
      center={center}
      defaultCenter={ center }>
          <PoiMarkers pois={locations} />
          <Polyline
          strokeWeight={10}
          strokeColor={'#ff22cc88'}
          encodedPath={encode(path, 5)}
        />
        <Circle
          radius={500}
          center={center}
          //onRadiusChanged={setRadius}
          //onCenterChanged={changeCenter}
          strokeColor={'#0c4cb3'}
          strokeOpacity={1}
          strokeWeight={3}
          fillColor={'#3b82f6'}
          fillOpacity={0.3}
          //editable
          //draggable
        />

   </Map>
 </APIProvider>



    </div>
  );
}

