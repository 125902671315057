import React, { useState, useEffect} from "react";
import Routes from "./Routes";
import { AppContext } from "./lib/contextLib";
import { useHistory } from "react-router-dom";
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
//import "../node_modules/bootstrap/js/dist/dropdown.js";
//import "../node_modules/bootstrap/js/dist/modal.js";
//import "../node_modules/bootstrap/js/dist/collapse.js";
import "./style.scss";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
        //identityPoolId: 'us-east-1:3e8f3a90-4c50-4253-8718-74cedfa15959',

        // REQUIRED - Amazon Cognito Region
        region: 'ap-south-1',//'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-south-1_g2cvmDZOB',//'us-east-1_7Vm1KxluQ',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2be6q1ses8p813dlhp04rrkjc6',//'bbl5de85njhcs8aep7f0mg762',

        //storage: localStorage,

  },

  API: {
    endpoints: [
      {
        name: "xdent",
        endpoint: "https://ic6kqkyyz4.execute-api.us-east-1.amazonaws.com/dev",
        region: 'us-east-1',
        //custom_header: async () => {
          //return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
           //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
           //return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        //}
      }
    ]
  }

});

function App2() {
const history = useHistory();
const [isAuthenticating, setIsAuthenticating] = useState(true);
const [isAuthenticated, userHasAuthenticated] = useState(false);
const [sub, setSub] = useState('');

useEffect(() => {
  onLoad();
}, []);


async function handleLogout() {
  await Auth.signOut();
  userHasAuthenticated(false);

history.push("/");

}


async function onLoad() {
  try {



    await Auth.currentSession();
    userHasAuthenticated(true);
    const user=await Auth.currentUserInfo();
    //console.log(user);
    setSub(user.attributes.sub);



  }
  catch(e) {
    if (e !== 'No current user') {
//onError(e);
	console.log(e);
    }
  }
  setIsAuthenticating(false);
}


return (
  !isAuthenticating && (
    <div className="bg-dark vh-min-100">
      <AppContext.Provider value={{ sub, isAuthenticated, userHasAuthenticated, history, handleLogout }}>
        <Routes />
      </AppContext.Provider>
    </div>
  )
);


}

export default App2;
